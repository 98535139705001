define("ember-svg-jar/inlined/play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.18 7.203L0 14.088v-14l9.18 7.115z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "10",
      "height": "15",
      "viewBox": "0 0 10 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});