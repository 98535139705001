define("ember-form-for/helpers/form-for/humanize", ["exports", "@ember/component/helper", "ember-form-for/utils/strings"], function (_exports, _helper, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formForHumanize = formForHumanize;
  function formForHumanize([string]) {
    return (0, _strings.humanize)(string);
  }
  var _default = _exports.default = (0, _helper.helper)(formForHumanize);
});