define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.546 20.08l5.839 5.838-1.93 1.929-5.836-5.839a12.214 12.214 0 01-7.658 2.684C6.19 24.692.692 19.196.692 12.423.692 5.651 6.19.154 12.962.154c6.772 0 12.268 5.497 12.268 12.27a12.214 12.214 0 01-2.684 7.656zm-2.735-1.011a9.511 9.511 0 002.693-6.646 9.54 9.54 0 00-9.543-9.542 9.54 9.54 0 00-9.542 9.542 9.54 9.54 0 009.542 9.543 9.512 9.512 0 006.646-2.693l.204-.204z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "29",
      "height": "28",
      "viewBox": "0 0 29 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});