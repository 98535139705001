define("ember-svg-jar/inlined/right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 1.417l3.708 3.73L0 8.875l1.142 1.146L6 5.145 1.142.271 0 1.417z\" fill=\"#000\" fill-opacity=\".1\"/>",
    "attrs": {
      "class": "text-black/10",
      "width": "6",
      "height": "11",
      "viewBox": "0 0 6 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});