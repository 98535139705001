define("ember-validated-form/components/validated-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking", "rsvp", "ember-validated-form/passed-or-default"], function (_exports, _component, _templateFactory, _object, _component2, _tracking, _rsvp, _passedOrDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    <this.buttonComponent
      @onClick={{this.click}}
      @loading={{this.loading}}
      @disabled={{or @disabled this.loading}}
      @label={{@label}}
      @type={{@type}}
      ...attributes
    >{{yield}}</this.buttonComponent>
  {{else}}
    <this.buttonComponent
      @onClick={{this.click}}
      @loading={{this.loading}}
      @disabled={{or @disabled this.loading}}
      @label={{@label}}
      @type={{@type}}
      ...attributes
    />
  {{/if}}
  */
  {
    "id": "2ofevj9I",
    "block": "[[[41,[48,[30,5]],[[[1,\"  \"],[8,[30,0,[\"buttonComponent\"]],[[17,1]],[[\"@onClick\",\"@loading\",\"@disabled\",\"@label\",\"@type\"],[[30,0,[\"click\"]],[30,0,[\"loading\"]],[28,[37,2],[[30,2],[30,0,[\"loading\"]]],null],[30,3],[30,4]]],[[\"default\"],[[[[18,5,null]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[30,0,[\"buttonComponent\"]],[[17,1]],[[\"@onClick\",\"@loading\",\"@disabled\",\"@label\",\"@type\"],[[30,0,[\"click\"]],[30,0,[\"loading\"]],[28,[37,2],[[30,2],[30,0,[\"loading\"]]],null],[30,3],[30,4]]],null],[1,\"\\n\"]],[]]]],[\"&attrs\",\"@disabled\",\"@label\",\"@type\",\"&default\"],false,[\"if\",\"has-block\",\"or\",\"yield\"]]",
    "moduleName": "ember-validated-form/components/validated-button.hbs",
    "isStrictMode": false
  });
  const ON_CLICK = "on-click";
  const ON_INVALID_CLICK = "on-invalid-click";
  let ValidatedButtonComponent = _exports.default = (_dec = (0, _passedOrDefault.default)("button"), _class = class ValidatedButtonComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_loading", _descriptor, this);
      _initializerDefineProperty(this, "buttonComponent", _descriptor2, this);
    }
    get loading() {
      return this.args.loading || this._loading;
    }
    async click(event) {
      // handle only clicks for custom buttons
      // everything else is handled by the validated form itself
      if (this.args.type !== "button") {
        return this.args.action(event);
      }
      event.preventDefault();
      if (this.args.triggerValidations) {
        this.args.markAsDirty();
      }
      const model = this.args.model;
      if (!model || !model.validate) {
        this.runCallback(ON_CLICK);
        return;
      }
      await model.validate();
      if (model.get("isInvalid")) {
        this.runCallback(ON_INVALID_CLICK);
      } else {
        this.runCallback(ON_CLICK);
      }
    }
    runCallback(callbackProp) {
      const callback = this.args[callbackProp];
      if (typeof callback !== "function") {
        return;
      }
      this._loading = true;
      (0, _rsvp.resolve)(callback(this.args.model)).finally(() => {
        this._loading = false;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "buttonComponent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ValidatedButtonComponent);
});