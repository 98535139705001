define("ember-form-for/components/form-fields/week-field", ["exports", "ember-form-for/components/form-fields/text-field", "ember-form-for/utils/date"], function (_exports, _textField, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _textField.default.extend({
    control: 'one-way-week',
    serializeValue(value) {
      if (value instanceof Date) {
        return (0, _date.toWeekString)(value);
      }
      return value;
    },
    deserializeValue(value) {
      if (value != null) {
        return (0, _date.fromWeekString)(value);
      }
      return value;
    }
  });
});