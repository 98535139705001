define("ember-form-for/components/form-fields/checkbox-group/option", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/string", "@ember/utils", "ember-form-for/templates/components/form-fields/checkbox-group/option", "ember-form-for/utils/strings"], function (_exports, _component, _object, _computed, _service, _string, _utils, _option, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    layout: _option.default,
    config: (0, _service.inject)('ember-form-for/config'),
    modelName: (0, _computed.or)('object.modelName', 'object.constructor.modelName'),
    labelText: (0, _object.computed)('value', 'label', 'labelI18nKey', 'i18n.locale', function () {
      let i18n = this.i18n;
      let label = this.label;
      if ((0, _utils.isPresent)(label)) {
        return label;
      } else if ((0, _utils.isPresent)(i18n)) {
        return i18n.t(this.labelI18nKey);
      } else {
        return this.label || (0, _strings.humanize)(this.value);
      }
    }),
    labelI18nKey: (0, _object.computed)('config.i18nKeyPrefix', 'modelName', 'propertyName', 'value', function () {
      let value = this.value;
      if ((0, _utils.isPresent)(value)) {
        value = (0, _string.dasherize)(value.toString());
      }
      return [(0, _object.get)(this, 'config.i18nKeyPrefix'), (0, _string.dasherize)(this.modelName || ''), (0, _string.dasherize)(this.propertyName || ''), value].filter(x => !!x).join('.');
    })
  });
});