define("ember-form-for/templates/components/form-fields/checkbox-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "23g5Phph",
    "block": "[[[6,[39,0],[[33,1]],[[\"class\",\"classNames\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"form\",\"hint\",\"label\",\"control\",\"update\"],[\"checkbox-field\",[33,2],[33,3],[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],[33,10],[33,11],[28,[37,12],[[30,0],[33,13]],null]]],[[\"default\"],[[[[6,[30,1,[\"label\"]],null,[[\"required\"],[[33,14]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[30,1,[\"control\"]],null,[[\"accesskey\",\"autocomplete\",\"autofocus\",\"autosave\",\"dir\",\"disabled\",\"hidden\",\"lang\",\"list\",\"required\",\"tabindex\",\"title\"],[[33,15],[33,16],[33,17],[33,18],[33,19],[33,20],[33,21],[33,22],[33,23],[33,14],[33,24],[33,25]]]]],[1,\"\\n    \"],[1,[30,1,[\"labelText\"]]],[1,\"\\n\"]],[]]]]],[1,\"  \"],[1,[30,1,[\"errors\"]]],[1,\"\\n  \"],[1,[30,1,[\"hint\"]]],[1,\"\\n\"]],[1]]]]]],[\"f\"],false,[\"form-field\",\"propertyName\",\"fieldClasses\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"form\",\"hint\",\"label\",\"control\",\"action\",\"update\",\"required\",\"accesskey\",\"autocomplete\",\"autofocus\",\"autosave\",\"dir\",\"disabled\",\"hidden\",\"lang\",\"list\",\"tabindex\",\"title\"]]",
    "moduleName": "ember-form-for/templates/components/form-fields/checkbox-field.hbs",
    "isStrictMode": false
  });
});