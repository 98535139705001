define("ember-svg-jar/inlined/instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.6 4.858a3.421 3.421 0 014.833 0 3.406 3.406 0 010 4.823 3.421 3.421 0 01-4.833 0 3.407 3.407 0 010-4.823zm2.416.078a2.34 2.34 0 00-1.653.684 2.33 2.33 0 001.653 3.983A2.34 2.34 0 008.67 8.92a2.33 2.33 0 00-1.653-3.984z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.137 1.348c-.811 0-1.589.322-2.162.894A3.048 3.048 0 001.079 4.4v5.743c0 .81.322 1.586.896 2.158a3.061 3.061 0 002.162.894h5.756c.81 0 1.588-.322 2.162-.894a3.048 3.048 0 00.895-2.158V4.4c0-.81-.322-1.586-.895-2.158a3.061 3.061 0 00-2.162-.894H4.137zm-2.925.133A4.141 4.141 0 014.137.27h5.756c1.097 0 2.149.435 2.925 1.21A4.124 4.124 0 0114.03 4.4v5.743a4.124 4.124 0 01-1.212 2.92 4.141 4.141 0 01-2.925 1.208H4.137a4.141 4.141 0 01-2.925-1.209A4.124 4.124 0 010 10.143V4.4c0-1.095.436-2.145 1.212-2.92z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});