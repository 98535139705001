define("ember-form-for/components/form-errors", ["exports", "ember-form-for/templates/components/form-errors", "@ember/component", "@ember/object"], function (_exports, _formErrors, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _formErrors.default,
    tagName: '',
    limitedErrors: (0, _object.computed)('errors.[]', 'maxErrors', function () {
      let errors = this.errors;
      let maxErrors = this.maxErrors;
      if (maxErrors) {
        return errors.slice(0, maxErrors);
      }
      return errors;
    }),
    joinedErrorClasses: (0, _object.computed)('errorClasses', function () {
      return (this.errorClasses || []).join(' ');
    })
  });
});