define("ember-form-for/templates/components/form-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "R4Tu5Oux",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]],[41,[33,4],[[[10,1],[14,0,\"star\"],[12],[1,\"*\"],[13]],[]],null],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"label\",\"required\"]]",
    "moduleName": "ember-form-for/templates/components/form-label.hbs",
    "isStrictMode": false
  });
});