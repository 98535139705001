define("ember-svg-jar/inlined/go", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"29.769\" cy=\"30.001\" r=\"29.077\" fill=\"#fff\"/><path d=\"M27 24.664l5.135 5.164L27 34.992l1.58 1.586 6.728-6.75-6.727-6.75L27 24.664z\" fill=\"#000\"/>",
    "attrs": {
      "width": "59",
      "height": "60",
      "viewBox": "0 0 59 60",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});