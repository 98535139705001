define("ember-validated-form/components/validated-form", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/runloop", "@glimmer/component", "@glimmer/tracking", "rsvp"], function (_exports, _component, _templateFactory, _object, _runloop, _component2, _tracking, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form autocomplete={{@autocomplete}} class={{if this.submitted "submitted"}}>
    {{yield
      (hash
        model=@model
        loading=this.loading
        input=(component
          "validated-input"
          model=@model
          submitted=this.submitted
          validateBeforeSubmit=@validateBeforeSubmit
        )
        submit=(component
          "validated-button"
          type="submit"
          loading=this.loading
          label="Save"
          action=this.submit
        )
        button=(component
          "validated-button"
          type="button"
          loading=this.loading
          label="Action"
          model=@model
          markAsDirty=this.markAsDirty
        )
      )
    }}
  </form>
  */
  {
    "id": "1dJ3uLYD",
    "block": "[[[10,\"form\"],[15,\"autocomplete\",[30,1]],[15,0,[52,[30,0,[\"submitted\"]],\"submitted\"]],[12],[1,\"\\n  \"],[18,4,[[28,[37,2],null,[[\"model\",\"loading\",\"input\",\"submit\",\"button\"],[[30,2],[30,0,[\"loading\"]],[50,\"validated-input\",0,null,[[\"model\",\"submitted\",\"validateBeforeSubmit\"],[[30,2],[30,0,[\"submitted\"]],[30,3]]]],[50,\"validated-button\",0,null,[[\"type\",\"loading\",\"label\",\"action\"],[\"submit\",[30,0,[\"loading\"]],\"Save\",[30,0,[\"submit\"]]]]],[50,\"validated-button\",0,null,[[\"type\",\"loading\",\"label\",\"model\",\"markAsDirty\"],[\"button\",[30,0,[\"loading\"]],\"Action\",[30,2],[30,0,[\"markAsDirty\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"@autocomplete\",\"@model\",\"@validateBeforeSubmit\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-validated-form/components/validated-form.hbs",
    "isStrictMode": false
  });
  const PROP_ON_SUBMIT = "on-submit";
  const PROP_ON_INVALID_SUBMIT = "on-invalid-submit";
  let ValidatedFormComponent = _exports.default = (_class = class ValidatedFormComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "loading", _descriptor, this);
      _initializerDefineProperty(this, "submitted", _descriptor2, this);
      _initializerDefineProperty(this, "validateBeforeSubmit", _descriptor3, this);
      if (this.args.model && this.args.model.validate) {
        (0, _runloop.scheduleOnce)("actions", this, "validateModel", this.args.model);
      }
    }
    validateModel(model) {
      model.validate();
    }
    markAsDirty() {
      this.submitted = true;
    }
    async submit(event) {
      event.preventDefault();
      this.submitted = true;
      const model = this.args.model;
      if (!model || !model.validate) {
        this.runCallback(PROP_ON_SUBMIT);
        return false;
      }
      await model.validate();
      if (model.get("isInvalid")) {
        this.runCallback(PROP_ON_INVALID_SUBMIT);
      } else {
        this.runCallback(PROP_ON_SUBMIT);
      }
      return false;
    }
    runCallback(callbackProp) {
      const callback = this.args[callbackProp];
      if (typeof callback !== "function") {
        return;
      }
      this.loading = true;
      (0, _rsvp.resolve)(callback(this.args.model)).finally(() => {
        this.loading = false;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "submitted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "validateBeforeSubmit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "markAsDirty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markAsDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ValidatedFormComponent);
});