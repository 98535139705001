define("ember-svg-jar/inlined/location-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.591 0c1.748 0 3.425.722 4.66 2.008a7 7 0 011.931 4.85c0 2.838-2.08 5.813-6.151 8.99a.714.714 0 01-.88 0C2.08 12.67 0 9.696 0 6.858a7 7 0 011.93-4.85A6.464 6.464 0 016.592 0zm0 4.571c-.582 0-1.141.241-1.553.67a2.333 2.333 0 00-.644 1.616c0 .606.232 1.188.644 1.616.412.429.97.67 1.553.67s1.142-.241 1.554-.67c.412-.428.643-1.01.643-1.616 0-.606-.231-1.187-.643-1.616a2.155 2.155 0 00-1.554-.67z\" fill=\"#000\" fill-opacity=\".08\"/>",
    "attrs": {
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});