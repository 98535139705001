define("ember-svg-jar/inlined/youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.714 7.414l3.707-2.143L5.714 3.13v4.285zm8.257-5.593c.093.336.158.786.2 1.358.05.571.072 1.064.072 1.492l.043.6c0 1.565-.115 2.715-.315 3.45-.178.643-.592 1.058-1.235 1.236-.336.093-.95.157-1.893.2-.929.05-1.779.072-2.564.072l-1.136.043c-2.993 0-4.857-.115-5.593-.315C.907 9.78.493 9.364.314 8.721c-.093-.335-.157-.785-.2-1.357a17.186 17.186 0 01-.071-1.493L0 5.271c0-1.564.114-2.714.314-3.45C.493 1.18.907.764 1.55.586c.336-.093.95-.157 1.893-.2C4.37.336 5.22.314 6.007.314L7.143.271c2.993 0 4.857.115 5.593.315.643.178 1.057.593 1.235 1.235z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "15",
      "height": "11",
      "viewBox": "0 0 15 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});