define("ember-validated-form/components/validated-input/types/textarea", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <textarea
    class={{class-list
      (if
        (macroCondition (macroGetOwnConfig "isUikit"))
        (class-list
          "uk-textarea"
          (if @isValid "uk-form-success")
          (if @isInvalid "uk-form-danger")
        )
      )
      (if
        (macroCondition (macroGetOwnConfig "isBootstrap"))
        (class-list
          "form-control" (if @isValid "is-valid") (if @isInvalid "is-invalid")
        )
      )
    }}
    {{on "input" this.onUpdate}}
    {{on "blur" @setDirty}}
    ...attributes
  >
  </textarea>
  */
  {
    "id": "V1k1+69w",
    "block": "[[[11,\"textarea\"],[16,0,[28,[37,0],[[28,[37,0],[\"uk-textarea\",[52,[30,1],\"uk-form-success\"],[52,[30,2],\"uk-form-danger\"]],null],[27]],null]],[17,3],[4,[38,2],[\"input\",[30,0,[\"onUpdate\"]]],null],[4,[38,2],[\"blur\",[30,4]],null],[12],[1,\"\"],[13]],[\"@isValid\",\"@isInvalid\",\"&attrs\",\"@setDirty\"],false,[\"class-list\",\"if\",\"on\"]]",
    "moduleName": "ember-validated-form/components/validated-input/types/textarea.hbs",
    "isStrictMode": false
  });
  let TextareaComponent = _exports.default = (_class = class TextareaComponent extends _component2.default {
    onUpdate(event) {
      event.preventDefault();
      this.args.update(event.target.value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TextareaComponent);
});