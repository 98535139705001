define("ember-svg-jar/inlined/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 7.634H6.315m-3.772 0H1m1.543 0c0-.436.199-.855.552-1.164a2.033 2.033 0 011.333-.482c.5 0 .98.174 1.334.482.353.309.552.728.552 1.164 0 .437-.199.855-.552 1.164a2.033 2.033 0 01-1.334.482c-.5 0-.98-.173-1.333-.482a1.548 1.548 0 01-.552-1.164zM17 2.646h-2.685m-3.772 0H1m9.543 0c0-.437.199-.855.552-1.164A2.033 2.033 0 0112.428 1c.248 0 .493.043.722.125.229.083.436.204.611.357.176.153.315.334.41.534a1.466 1.466 0 010 1.26 1.65 1.65 0 01-.41.534 1.924 1.924 0 01-.611.356 2.126 2.126 0 01-.722.126c-.5 0-.98-.174-1.333-.482a1.548 1.548 0 01-.552-1.164z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "11",
      "viewBox": "0 0 18 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});