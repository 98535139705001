define("ember-cli-flash/services/flash-messages", ["exports", "@ember/service", "@ember/utils", "@ember/debug", "@ember/object", "@ember/object/computed", "@ember/string", "@ember/polyfills", "@ember/array", "ember-cli-flash/flash/object", "ember-cli-flash/utils/object-without", "@ember/application", "ember-cli-flash/utils/flash-message-options", "ember-cli-flash/utils/get-with-default"], function (_exports, _service, _utils, _debug, _object, _computed, _string, _polyfills, _array, _object2, _objectWithout, _application, _flashMessageOptions, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    isEmpty: (0, _computed.equal)('queue.length', 0).readOnly(),
    _guids: (0, _computed.mapBy)('queue', '_guid').readOnly(),
    arrangedQueue: (0, _computed.sort)('queue', function (a, b) {
      if (a.priority < b.priority) {
        return 1;
      } else if (a.priority > b.priority) {
        return -1;
      }
      return 0;
    }).readOnly(),
    init() {
      this._super(...arguments);
      this._setDefaults();
      this.queue = (0, _array.A)();
    },
    willDestroy() {
      this._super(...arguments);
      this.clearMessages();
    },
    add(options = {}) {
      this._enqueue(this._newFlashMessage(options));
      return this;
    },
    clearMessages() {
      const flashes = (0, _object.get)(this, 'queue');
      if ((0, _utils.isNone)(flashes)) {
        return;
      }
      flashes.forEach(flash => flash.destroyMessage());
      flashes.clear();
      return this;
    },
    registerTypes(types = (0, _array.A)()) {
      types.forEach(type => this._registerType(type));
      return this;
    },
    peekFirst() {
      return (0, _object.get)(this, 'queue.firstObject');
    },
    peekLast() {
      return (0, _object.get)(this, 'queue.lastObject');
    },
    getFlashObject() {
      const errorText = 'A flash message must be added before it can be returned';
      (false && !((0, _object.get)(this, 'queue').length) && (0, _debug.assert)(errorText, (0, _object.get)(this, 'queue').length));
      return this.peekLast();
    },
    _newFlashMessage(options = {}) {
      (false && !((0, _object.get)(this, 'defaultPreventDuplicates') ? options.message : true) && (0, _debug.assert)('The flash message cannot be empty when preventDuplicates is enabled.', (0, _object.get)(this, 'defaultPreventDuplicates') ? options.message : true));
      (false && !((0, _object.get)(options, 'preventDuplicates') ? options.message : true) && (0, _debug.assert)('The flash message cannot be empty when preventDuplicates is enabled.', (0, _object.get)(options, 'preventDuplicates') ? options.message : true));
      const flashService = this;
      const allDefaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      const defaults = (0, _objectWithout.default)(allDefaults, ['types', 'injectionFactories', 'preventDuplicates']);
      const flashMessageOptions = (0, _polyfills.assign)({}, defaults, {
        flashService
      });
      for (let key in options) {
        const value = (0, _object.get)(options, key);
        const option = this._getOptionOrDefault(key, value);
        (0, _object.set)(flashMessageOptions, key, option);
      }
      return _object2.default.create(flashMessageOptions);
    },
    _getOptionOrDefault(key, value) {
      const defaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      const defaultOption = (0, _object.get)(defaults, key);
      if ((0, _utils.typeOf)(value) === 'undefined') {
        return defaultOption;
      }
      return value;
    },
    flashMessageDefaults: (0, _object.computed)(function () {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const overrides = (0, _getWithDefault.default)(config, 'flashMessageDefaults', {});
      return (0, _flashMessageOptions.default)(overrides);
    }),
    _setDefaults() {
      const defaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      for (let key in defaults) {
        const classifiedKey = (0, _string.classify)(key);
        const defaultKey = `default${classifiedKey}`;
        (0, _object.set)(this, defaultKey, defaults[key]);
      }
      this.registerTypes((0, _getWithDefault.default)(this, 'defaultTypes', (0, _array.A)()));
    },
    _registerType(type) {
      (false && !(type) && (0, _debug.assert)('The flash type cannot be undefined', type));
      this[type] = (message, options = {}) => {
        const flashMessageOptions = (0, _polyfills.assign)({}, options);
        (0, _object.setProperties)(flashMessageOptions, {
          message,
          type
        });
        return this.add(flashMessageOptions);
      };
    },
    _hasDuplicate(guid) {
      return (0, _object.get)(this, '_guids').includes(guid);
    },
    _enqueue(flashInstance) {
      const instancePreventDuplicates = (0, _object.get)(flashInstance, 'preventDuplicates');
      const preventDuplicates = typeof instancePreventDuplicates === 'boolean' ?
      // always prefer instance option over global option
      instancePreventDuplicates : (0, _object.get)(this, 'defaultPreventDuplicates');
      if (preventDuplicates) {
        const guid = (0, _object.get)(flashInstance, '_guid');
        if (this._hasDuplicate(guid)) {
          (false && (0, _debug.warn)('Attempting to add a duplicate message to the Flash Messages Service', false, {
            id: 'ember-cli-flash.duplicate-message'
          }));
          return;
        }
      }
      return (0, _object.get)(this, 'queue').pushObject(flashInstance);
    }
  });
});