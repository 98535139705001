define("ember-form-for/templates/components/form-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RPshhgQq",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"tagName\",\"class\"],[[33,3],[33,4]]],[[\"default\"],[[[[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[6,[39,2],null,[[\"tagName\",\"id\",\"role\"],[[33,8],[28,[37,9],[[33,10],\"-\",[30,2]],null],\"alert\"]],[[\"default\"],[[[[41,[48,[30,3]],[[[1,\"          \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]],[[[41,[30,1,[\"message\"]],[[[1,\"            \"],[1,[30,1,[\"message\"]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,1]],[1,\"\\n\"]],[]]]],[]]]],[]]]]]],[1,2]],null]],[]]]]]],[]],null]],[\"error\",\"index\",\"&default\"],false,[\"if\",\"errors\",\"form-for/custom-tag\",\"errorsTagName\",\"joinedErrorClasses\",\"each\",\"-track-array\",\"limitedErrors\",\"messageTagName\",\"concat\",\"errorId\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-form-for/templates/components/form-errors.hbs",
    "isStrictMode": false
  });
});