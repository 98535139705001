define("ember-svg-jar/inlined/in-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"10\" cy=\"10.088\" r=\"9.25\" stroke=\"#fff\" stroke-width=\"1.5\"/><path d=\"M14 10.088a4 4 0 01-8 0\" stroke=\"#fff\" stroke-width=\"1.5\"/>",
    "attrs": {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});