define("ember-svg-jar/inlined/double-caret", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.79 11.29l-4.29 4.3-4.29-4.3a1.004 1.004 0 10-1.42 1.42l5 5a.998.998 0 001.42 0l5-5a1.005 1.005 0 00-1.42-1.42zM2.21 6.71l4.29-4.3 4.29 4.3a1 1 0 001.638-.325 1 1 0 00-.219-1.095l-5-5a1 1 0 00-1.42 0l-5 5a1.004 1.004 0 001.42 1.42z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "13",
      "height": "18",
      "viewBox": "0 0 13 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});