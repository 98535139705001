define("ember-svg-jar/inlined/close2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5.5A7.498 7.498 0 000 8c0 4.144 3.356 7.5 7.5 7.5S15 12.144 15 8 11.644.5 7.5.5zm3.113 9.637a.702.702 0 010 .976.702.702 0 01-.976 0L7.5 8.975l-2.138 2.138a.702.702 0 01-.974 0 .702.702 0 010-.976L6.524 8 4.387 5.862a.702.702 0 010-.974.702.702 0 01.976 0L7.5 7.024l2.137-2.138a.702.702 0 01.976 0 .702.702 0 010 .976L8.475 8l2.138 2.137z\" fill=\"#000\"/>",
    "attrs": {
      "width": "15",
      "height": "16",
      "viewBox": "0 0 15 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});