define("ember-form-for/components/form-controls/reset", ["exports", "ember-form-for/components/form-controls/button"], function (_exports, _button) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _button.default.extend({
    type: 'reset',
    click(e, ...args) {
      e.preventDefault();
      if (this.reset !== undefined) {
        this.reset(...args);
      }
    }
  });
});