define("ember-validated-form/components/validated-input/label", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    class={{if (macroCondition (macroGetOwnConfig "isUikit")) "uk-form-label"}}
    for={{@inputId}}
    ...attributes
  >
    {{yield}}{{@label}}{{if (and @label @required) " *"}}
  </label>
  */
  {
    "id": "2acXX5wK",
    "block": "[[[11,\"label\"],[24,0,\"uk-form-label\"],[16,\"for\",[30,1]],[17,2],[12],[1,\"\\n  \"],[18,5,null],[1,[30,3]],[1,[52,[28,[37,2],[[30,3],[30,4]],null],\" *\"]],[1,\"\\n\"],[13]],[\"@inputId\",\"&attrs\",\"@label\",\"@required\",\"&default\"],false,[\"yield\",\"if\",\"and\"]]",
    "moduleName": "ember-validated-form/components/validated-input/label.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});