define("ember-form-for/helpers/form-for/merge-custom-form-field", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formForMergeCustomFormField = formForMergeCustomFormField;
  function formForMergeCustomFormField([formFieldsHash], {
    name,
    component
  }) {
    formFieldsHash[name] = component;
  }
  var _default = _exports.default = (0, _helper.helper)(formForMergeCustomFormField);
});