define("ember-svg-jar/inlined/tiktok-black", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.934 1.723A2.553 2.553 0 017.258 0H5.287v7.578c-.015.41-.195.799-.504 1.084a1.69 1.69 0 01-1.148.444c-.905 0-1.658-.71-1.658-1.59 0-1.05 1.059-1.839 2.15-1.515V4.07C1.925 3.789 0 5.427 0 7.517 0 9.552 1.76 11 3.629 11c2.002 0 3.629-1.558 3.629-3.483V3.673c.799.55 1.758.845 2.742.843V2.628s-1.199.055-2.066-.905z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "10",
      "height": "11",
      "viewBox": "0 0 10 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});