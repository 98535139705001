define("ember-svg-jar/inlined/online", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".75\" y=\"1.021\" width=\"14.5\" height=\"10.5\" rx=\"1.25\" stroke=\"#fff\" stroke-width=\"1.5\"/><path stroke=\"#fff\" stroke-width=\"1.5\" d=\"M7.75 11.271v3.905M2 15.521h12\"/>",
    "attrs": {
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});