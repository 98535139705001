define("ember-svg-jar/inlined/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.293 6.889a1 1 0 000 1.414l6.364 6.364a1 1 0 001.414-1.414L2.414 7.596l5.657-5.657A1 1 0 006.657.525L.293 6.889zM25 6.596H1v2h24v-2z\" fill=\"#1566E7\"/>",
    "attrs": {
      "width": "25",
      "height": "15",
      "viewBox": "0 0 25 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});