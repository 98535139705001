define("ember-validated-form/components/validated-input/render/wrapper", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isUikit"))}}
    <div class="uk-form-controls">{{yield}}</div>
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "05yeGeal",
    "block": "[[[1,\"  \"],[10,0],[14,0,\"uk-form-controls\"],[12],[18,1,null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-validated-form/components/validated-input/render/wrapper.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});