define("ember-svg-jar/inlined/arrow-left-short", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.252 6.704a1 1 0 000 1.415l6.37 6.37a1 1 0 101.415-1.416L3.375 7.412 9.037 1.75A1 1 0 007.62.334l-6.369 6.37zm18.756-.293H1.96v2.001h18.048V6.411z\" fill=\"#9FAEBC\"/>",
    "attrs": {
      "width": "21",
      "height": "15",
      "viewBox": "0 0 21 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});