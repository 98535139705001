define("ember-cli-meta-tags/components/head-tag", ["exports", "@ember/object/computed", "@ember/component", "ember-cli-meta-tags/templates/components/head-tag"], function (_exports, _computed, _component, _headTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _headTag.default,
    init() {
      this._super(...arguments);
      this.set('tagName', this.get('headTag.type'));
    },
    // expected head tag attributes
    attributeBindings: ['href', 'target', 'charset', 'crossorigin', 'dir', 'hreflang', 'media', 'rel', 'rev', 'sizes', 'type', 'content', 'http-equiv', 'name', 'scheme', 'async', 'defer', 'src', 'property', 'itemprop'],
    href: (0, _computed.reads)('headTag.attrs.href'),
    target: (0, _computed.reads)('headTag.attrs.target'),
    charset: (0, _computed.reads)('headTag.attrs.charset'),
    crossorigin: (0, _computed.reads)('headTag.attrs.crossorigin'),
    dir: (0, _computed.reads)('headTag.attrs.dir'),
    hreflang: (0, _computed.reads)('headTag.attrs.hreflang'),
    media: (0, _computed.reads)('headTag.attrs.media'),
    rel: (0, _computed.reads)('headTag.attrs.rel'),
    rev: (0, _computed.reads)('headTag.attrs.rev'),
    sizes: (0, _computed.reads)('headTag.attrs.sizes'),
    type: (0, _computed.reads)('headTag.attrs.type'),
    content: (0, _computed.reads)('headTag.attrs.content'),
    'http-equiv': (0, _computed.reads)('headTag.attrs.http-equiv'),
    name: (0, _computed.reads)('headTag.attrs.name'),
    scheme: (0, _computed.reads)('headTag.attrs.scheme'),
    async: (0, _computed.reads)('headTag.attrs.async'),
    defer: (0, _computed.reads)('headTag.attrs.defer'),
    src: (0, _computed.reads)('headTag.attrs.src'),
    property: (0, _computed.reads)('headTag.attrs.property'),
    itemprop: (0, _computed.reads)('headTag.attrs.itemprop')
  });
});