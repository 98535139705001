define("ember-form-for/utils/strings", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titlecase = _exports.humanize = void 0;
  const WORD_SEPERATORS = new RegExp('[-_\\. ]', 'g');
  const humanize = string => {
    return (0, _string.capitalize)((0, _string.decamelize)(string.toString()).replace(WORD_SEPERATORS, ' '));
  };
  _exports.humanize = humanize;
  const titlecase = string => (0, _string.decamelize)(string).split(WORD_SEPERATORS).map(w => (0, _string.capitalize)(w)).join(' ');
  _exports.titlecase = titlecase;
});