define("ember-svg-jar/inlined/icon-cross-clear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M456 1.1C285.5 12.6 131.3 114.9 53 268.5c-96.9 190.3-58.4 422.4 94.9 571.7 77.2 75.3 173.7 122.1 279.6 135.7 126.4 16.2 251.4-16.5 354.5-92.7 38.3-28.3 77.9-68.6 105.8-107.5 141.1-196.8 118-465.5-54.5-634.8-23.7-23.2-45.4-40.9-71.8-58.6-90-60-197-88.5-305.5-81.2zM293.5 194.5c7.7 2.1 16.9 6.4 24.2 11.1 3.7 2.4 35.8 33.7 89.1 86.8l83.2 83.1 83.3-83.1c53.2-53.1 85.3-84.4 89-86.8 7.1-4.6 16.1-8.7 24.7-11.2 5-1.4 9.7-1.8 20.5-1.8 16.4 0 22.5 1.3 36 7.9 16.3 7.9 28.1 19.7 36 36 6.5 13.3 7.9 19.6 7.9 35.5.1 14-.8 19-5.4 31.2-5.3 14.1-7.7 16.8-94.8 104L604.5 490l82.6 82.7c54 54.1 84.1 85 86.8 89.1 27.4 40.9 13.1 96.3-30.4 117.8-12.5 6.1-22.1 8.4-36 8.4-16.9 0-32.2-4.7-46.2-14.1-2.7-1.7-42.3-40.6-88-86.3L490 604.5l-83.2 83.1c-52.7 52.5-85.4 84.4-89 86.8-15.6 10.1-26.2 13.1-45.8 13.1-12.4 0-15.6-.3-22-2.3-22.3-7-39.8-21.7-49.5-41.7-5.5-11.4-7.4-18.4-8.1-31-1.1-16.9 2.2-31.1 10.7-46.3 3.9-7 11.8-15.2 88.3-92l84-84.2-82.6-82.8c-89.4-89.5-89.5-89.6-95.6-106.4-10-27.6-5.3-56.4 12.8-79.3 11.4-14.4 29.6-25.1 48.5-28.5 7.2-1.3 27.9-.4 35 1.5z\" fill=\"#b8c2cc\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 980 980"
    }
  };
});