define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"21\" cy=\"21\" r=\"21\" transform=\"rotate(-180 21 21)\" fill=\"#000\" fill-opacity=\".05\"/><path d=\"M27 16.209L25.791 15 21 19.791 16.209 15 15 16.209 19.791 21 15 25.791 16.209 27 21 22.209 25.791 27 27 25.791 22.209 21 27 16.209z\" fill=\"#000\"/>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});