define("ember-svg-jar/inlined/favourited", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 15.962V2.076C0 1.598.174 1.19.522.85.871.51 1.29.34 1.777.34h8.89c.488 0 .907.17 1.255.51.349.34.522.749.522 1.226v13.886l-6.222-2.604L0 15.962z\"/>",
    "attrs": {
      "width": "13",
      "height": "16",
      "viewBox": "0 0 13 16",
      "fill": "#fff",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});