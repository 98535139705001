define("ember-cli-meta-tags/templates/components/head-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pWrum09i",
    "block": "[[[1,[33,0,[\"content\"]]],[1,\"\\n\"]],[],false,[\"headTag\"]]",
    "moduleName": "ember-cli-meta-tags/templates/components/head-tag.hbs",
    "isStrictMode": false
  });
});