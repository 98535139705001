define("ember-form-for/components/form-fields/select-field", ["exports", "@ember/component", "@ember/object", "ember-form-for/templates/components/form-fields/select-field"], function (_exports, _component, _object, _selectField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SelectFieldComponent = _component.default.extend({
    tagName: '',
    layout: _selectField.default,
    control: 'one-way-select',
    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    }
  });
  SelectFieldComponent.reopenClass({
    positionalParams: ['propertyName', 'options']
  });
  var _default = _exports.default = SelectFieldComponent;
});