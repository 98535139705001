define("ember-svg-jar/inlined/left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 9.126l-3.708-3.73L6 1.667 4.858.521 0 5.396l4.858 4.875L6 9.127z\" fill=\"#000\"/>",
    "attrs": {
      "width": "6",
      "height": "11",
      "viewBox": "0 0 6 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});