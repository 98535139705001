define("ember-form-for/components/form-label", ["exports", "@ember/component", "ember-form-for/templates/components/form-label"], function (_exports, _component, _formLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FormLabelComponent = _component.default.extend({
    tagName: 'label',
    layout: _formLabel.default,
    attributeBindings: ['for', 'form']
  });
  FormLabelComponent.reopenClass({
    positionalParams: ['label']
  });
  var _default = _exports.default = FormLabelComponent;
});