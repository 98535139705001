define("ember-form-for/components/form-fields/custom-field", ["exports", "@ember/component", "@ember/object", "ember-form-for/templates/components/form-fields/custom-field"], function (_exports, _component, _object, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CustomFieldComponent = _component.default.extend({
    tagName: '',
    layout: _customField.default,
    control: 'one-way-input',
    update(object, propertyName, value) {
      (0, _object.set)(object, propertyName, value);
    }
  });
  CustomFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });
  var _default = _exports.default = CustomFieldComponent;
});