define("ember-svg-jar/inlined/arrow-up-short", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.105.921l.62-.62a.877.877 0 00-1.24 0l.62.62zM9.87 5.928a.877.877 0 001.24-1.24l-1.24 1.24zm-8.773-1.24a.877.877 0 101.241 1.24l-1.24-1.24zm4.13 8.514a.877.877 0 101.754 0H5.228zm.257-11.66L9.87 5.928l1.24-1.24L6.725.3l-1.24 1.24zm0-1.24L1.098 4.686l1.241 1.24 4.386-4.385-1.24-1.24zm-.257.62v6.14h1.754V.922H5.228zm0 6.14v6.14h1.754v-6.14H5.228z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "12",
      "height": "15",
      "viewBox": "0 0 12 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});